import React from "react";
import "./home.css";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
function Home() {
  return (
    <div className="home-page-main">
      <div className="home-page-carousel">
        <Carousel
          variant="dark"
          indicators={false}
          nextIcon={false}
          prevIcon={false}
        >
          <Carousel.Item autoPlay={true} interval={500} infiniteLoop={true}>
            <img
              className="home-main-img"
              src="Assets/1.png"
              alt="First slide"
            />
            {/* <div className="img-text-content">homemade fresh cookies</div> */}
            {/* <button className="shop-now-btn">shop now</button> */}
          </Carousel.Item>
          <Carousel.Item autoPlay={true} interval={500} infiniteLoop={true}>
            <img
              className="home-main-img"
              src="Assets/2.png"
              alt="First slide"
            />
            {/* <div className="img-text-content">home made fresh cookies</div> */}
            {/* <button className="shop-now-btn">shop now</button> */}
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item autoPlay={true} interval={500} infiniteLoop={true}>
            <img
              className="home-main-img"
              src="Assets/3.png"
              alt="First slide"
            />
            {/* <div className="img-text-content">home made fresh cookies</div> */}
            {/* <button className="shop-now-btn">shop now</button> */}
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="home-page-cards-block">
        <h3 className="homepage-head">Best Sellers</h3>
        <div className="homepage-paragraph">
          Explore our all new range of handmade decorative items
        </div>
        <div className="home-page-cart-1">
          <div className="home-page-individual-card">
            <img src="Assets/pc-img1.jpeg" className="card-imgs" alt="" />
            <div className="card-text">Honey Saffron Sandlewood Soap</div>
            <div className="card-value">₹275 per 100gm</div>
          </div>
          <div className="home-page-individual-card">
            <img src="Assets/pc-img2.jpeg" className="card-imgs" alt="" />
            <div className="card-text">Lavender Soap</div>
            <div className="card-value">₹265 per 125gm</div>
          </div>
          <div className="home-page-individual-card">
            <img
              src="Assets/pc-img3.jpeg"
              className="card-imgs"
              alt=""
              style={{ objectPosition: "right" }}
            />
            <div className="card-text">Hoeny Outmeal Scrub Soap</div>
            <div className="card-value">₹275 per 120gm</div>
          </div>
        </div>
      </div>
      <div className="horizontal-cooies">
        <hr className="hr-line-one" />
        <Link to="/products" className="cookies-btn">
          All Products
        </Link>
        <hr className="hr-line-two" />
      </div>
      <div className="follow-me-block">
        <h3 className="follow-me-head">
          Exclusive gifting with special offers
        </h3>
        <div className="follow-me-cards">
          <div className="follow-me-single-card">
            <img
              src="Assets/pc-img4.jpeg"
              alt=""
              className="follow-me-card-imgs"
            />
          </div>
          <div className="follow-me-single-card">
            <img
              src="Assets/pc-img5.jpeg"
              alt=""
              className="follow-me-card-imgs"
            />
          </div>
          <div className="follow-me-single-card">
            <img
              src="Assets/pc-img6.jpeg"
              alt=""
              className="follow-me-card-imgs"
            />
          </div>
        </div>
        <div className="follow-me-cards">
          <div className="follow-me-single-card">
            <img
              src="Assets/pc-img7.jpeg"
              alt=""
              className="follow-me-card-imgs"
            />
          </div>
          <div className="follow-me-single-card">
            <img
              src="Assets/pc-img8.jpeg"
              alt=""
              className="follow-me-card-imgs"
            />
          </div>
          <div className="follow-me-single-card">
            <img
              src="Assets/pc-img9.jpeg"
              alt=""
              className="follow-me-card-imgs"
            />
          </div>
        </div>
      </div>
      <div className="home-features-block">
        <div className="features-col">
          <div className="feature-icons">
            <img src="Assets/india.png" alt="" className="feature-icon-img" />
          </div>
          <div className="feature-head">Designed in India</div>
          {/* <div className="feature-paragraph">Chemical free</div> */}
        </div>
        <div className="verticle-line"></div>
        <div className="features-col">
          <div className="feature-icons">
            <img
              src="Assets/hand-made.png"
              alt=""
              className="feature-icon-img"
            />
          </div>
          <div className="feature-head"> Completely handmade</div>
          {/* <div className="feature-paragraph">Handcrafted</div> */}
        </div>
        <div className="verticle-line"></div>
        <div className="features-col">
          <div className="feature-icons">
            <img src="Assets/window.png" alt="" className="feature-icon-img" />
          </div>
          <div className="feature-head">A plethora of designs</div>
          {/* <div className="feature-paragraph">Paraben free</div> */}
        </div>
        <div className="verticle-line"></div>
        <div className="features-col">
          <div className="feature-icons">
            <img
              src="Assets/piggy-bank.png"
              alt=""
              className="feature-icon-img"
            />
          </div>
          <div className="feature-head">Pocket friendly</div>
          {/* <div className="feature-paragraph">Paraben free</div> */}
        </div>
      </div>
    </div>
  );
}

export default Home;
